<template>
  <div class="login_container">
    <!-- 头像 -->
    <div class="avatar_box">
      <!-- 称重表单 -->
      <!--      <el-form :model="weighForm" status-icon ref="editWeighFormRel" label-width="100px" class="weigh_form">-->
      <!--        <el-button class="btn" type="text" @click="weighbridge">开始称重</el-button>-->
      <!--      </el-form>-->

      <div v-show="!dialogVisible" class="data-area">
        <div class="licence">
          <span>{{ weigh.licence ? weigh.licence : "无车牌" }}</span>
        </div>
        <div class="weight">
          <span>{{ weight.data }}</span><i> Kg</i>
        </div>
        <div class="content">
          <span>{{ weigh.content ? weigh.content : "欢迎使用智能地磅" }}</span>
        </div>
        <div class="submit">
          <el-button class="btn-new" type="primary" @click="showDialog">确认重量</el-button>
        </div>
      </div>
    </div>

    <el-dialog :show-close="false" :close-on-click-modal="false" :visible.sync="dialogVisible" center width="500px" @close="dialogClose" :modal="false">
      <span slot="title" class="dialog_font">
         过磅信息确认
      </span>
      <el-form :model="licenceInfo" label-width="150px" scroll-to-error ref="formRul" >
        <el-form-item prop="times" v-show="isUpdate">
          <span slot="label" class="dialog_font">
            状态
          </span>
          <el-radio-group v-model="licenceInfo.times">
            <el-radio :label="1" @change="findLicenseList">第一次过磅</el-radio>
            <el-radio :label="2" @change="findLicenseList">第二次过磅</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item prop="licence">
          <span slot="label" class="dialog_font">
            车牌
          </span>
          <el-select v-if="isUpdate" v-model="weigh.licence" clearable placeholder="请选择" size="small" >
            <el-option v-for="item in licenceInfo.licenceList" :key="item.index" :label="item" :value="item" />
          </el-select>

          <div v-else>
            <span class="dialog_font">{{ weigh.licence ? weigh.licence : "无车牌" }}</span>
            <span v-show="weigh.licence === ''" style="margin-left: 30px;color: #FF6347;" @click="isUpdate = true">
              <i class="el-icon-edit"></i>点击修改
            </span>
          </div>
        </el-form-item>

        <el-form-item prop="weight">
          <span slot="label" class="dialog_font">
            重量
          </span>
          <span class="dialog_font"> {{ weigh.weight }} kg</span>
        </el-form-item>
      </el-form>

      <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" style="width: 100px;margin: 0 15px" icon="el-icon-close"> 关 闭 </el-button>
        <el-button type="primary" @click="submit" style="width: 100px;margin: 0 15px" icon="el-icon-check"> 确 定 </el-button>
      </span>
      </template>
    </el-dialog>
  </div>

</template>

<script>
import screenfull from 'screenfull'
import global from "../global/global.js"

export default {
  components:{
    screenfull,
  },
  data() {
    return {
      loading: false,
      isUpdate: false,
      dialogVisible: false,
      //数据绑定
      weighForm: {},
      licenceInfo: {
        licenceList: [],
        times: 1
      },
      //
      queryInfo: {
        query: '44444',
      },
      ws: null,
      weight: {
        data: 0
      },
      weigh: {
        isWeigh: 0,
        licence: "",
        content: "",
        weight: 0,
        status: 1000
      },
      reconnectTimer: null,
      timeout: null
    }
  },
    beforeMount() {this.getCurrentWeighInfo();

    },
    created() {
    this.initWebSocket();
  },
  beforeDestroy() {
    if (this.ws) {
      this.ws.close();
    }
  },
  watch: {
      weight: function (val) {
          if (val.data === 20) {
              clearTimeout(this.timeout);
              this.timeout = setTimeout(() => {
                  this.weight.data = 0;
                  this.weigh.licence = "";
              }, 1000);
          }
      }
  },
  methods: {
    showDialog() {
      if (this.weigh.status === 1000) {
        this.$message.success("暂未检测到车辆");
        return;
      }else {
        if (!this.weigh.isWeigh) {
          this.$message.warning("正在检测中，请稍后再试！");
          return;
        }
      }
      this.dialogVisible = true;
      this.isUpdate = false;
      this.findLicenseList();
    },
    findLicenseList() {
      this.$http.post('getLicence/' + this.licenceInfo.times).then(res => {
        console.log(res)
        if (res.data.code === 200) {
          console.log(res)
          this.licenceInfo.licenceList = res.data.data
        }
      })
    },
    submit() {
      this.$http.post('weigh/' + this.weigh.licence).then(res => {
        if (res.data.code !== 200) return this.$message.error({message:res.data.msg, center:true});
        this.$message.success({message: "提交成功", center: true});
        this.dialogVisible = !this.dialogVisible;
        console.log(res.data.data)
        this.weighForm = res.data.data;
      })
    },
    weighbridge() {
      this.$router.push('/weighbridge')
    },
    initWebSocket() { //初始化
      this.ws = new WebSocket(global.wsUrl);
      this.ws.onmessage = this.onmessage;
      this.ws.onopen = this.onopen;
      this.ws.onerror = this.onerror;
      this.ws.onclose = this.onclose;
    },
    onopen() {
      clearInterval(this.reconnectTimer);
      this.reconnectTimer = null;
      console.log("[ws] 连接成功");
    },
    onerror(e) {
      console.log('连接出错',e);
    },
    onmessage(res) {
      console.log(res.data);
      const obj = eval('(' + res.data + ')');
      if (obj && obj.type === 1) {
        this.weight = JSON.parse(res.data);
      }else if(obj && obj.type === 2) {
        this.weigh = JSON.parse(res.data);
      } else {
        console.log("未知数据")
      }
    },
    onclose(e) {
      if (this.ws) {
        this.ws.close();
      }
      console.log('断开连接', e);
      this.reconnect();
    },
    dialogClose() {
      this.$refs.formRul.resetFields();
    },
    reconnect() {
      if (this.reconnectTimer) {
        return
      }
      this.reconnectTimer = setInterval(
          () => {
            console.log("[WS] 正在重新连接...")
            this.initWebSocket();
          }, 3000
      )
    },
    getCurrentWeighInfo() {
        this.$http.get('/camera/info').then(res => {
            if (res.data.code !== 200) return this.$message.error({message:res.data.msg, center:true});
            console.log(res.data.data)
            this.weigh = res.data.data;
        })
    }
  }
}
</script>

<style scoped>
  .login_container {
    width: 100%;
    height: 100%;
    background: url("../assets/weight_bg.jpg") no-repeat center;
    background-size: 100% 100%;
    position: relative;
  }

  .avatar_box {
    width: 420px;
    height: 200px;
    position: absolute;
    /*background: url('../assets/weight_btn.jpg') no-repeat center;*/
    /*background-size: 100% 100%;*/
    left: 50%;
    top: 35%;
    transform: translate(-50%, -35%);
  }
  .weigh_form {
    padding: 0 20px;
    position: absolute;
    top: 95px;
    left: 100px;
  }

  .btn {
    color: #fff !important;
    font-size: 60px;
  }

  .btn:hover {
    color: #fff !important;
  }

  .data-area {
    width: 430px;
    height: 320px;
    padding-top: 20px;
    position: absolute;
    top: -40px;
    border: 1px solid rgba(0, 0, 0, .3);
    border-radius: 15px;
    box-shadow: 0 0 30px 10px rgba(0, 0, 0, .3);
    backdrop-filter: blur(1px);
  }
  .data-area::before {
    content: "";
    filter: blur(15px);
  }
  .licence {
    width: 430px;
    height: 40px;
    line-height: 40px;
  }
  .licence label {
    font-size: 12px;
    color: white;
  }
  .licence span {
    font-size: 24px;
    color: white;
    text-shadow:2px 5px 3px rgba(0, 0, 0, .9);
  }
  .weight {
    width: 430px;
    height: 80px;
    font-weight: bold;
    line-height: 80px;
  }
  .weight label {
    font-size: 24px;
    color: white;
  }
  .weight span {
    font-size: 80px;
    color: white;
    text-shadow:2px 5px 3px rgba(0, 0, 0, .9);
  }
  .weight i {
    color: white;
    font-size: 24px;
    text-shadow:2px 5px 3px rgba(0, 0, 0, .9);
  }
  .content {
    width: 430px;
    height: 40px;
    line-height: 40px;
  }
  .content label {
    font-size: 12px;
    color: white;
  }
  .content span {
    font-size: 24px;
    color: white;
    text-shadow:2px 5px 3px rgba(0, 0, 0, .9);
  }

  .submit {
    margin-top: 50px;
  }

  .btn-new {
    color: #fff !important;
    font-size: 50px;
    background-color: transparent;
    border: 2px solid white;
    border-radius: 15px;
    text-shadow: 2px 5px 3px rgba(0, 0, 0, .9);
    box-shadow: 2px 5px 3px rgba(0, 0, 0, .9);
  }

  .btn-new:hover {
    color: #fff !important;
    background-color: transparent;
  }

  .dialog_font {
    font-size: 25px;
    font-weight: bolder;
    color: white;
  }

  /deep/ .el-dialog {
    margin: 0 !important;
    position: absolute;
    top: 40%;
    left: 50%;
    transform:translate(-50%, -40%);
    border-radius: 20px;
    border: 1px solid rgba(27, 41, 81);
    box-shadow: 2px 5px 3px rgba(0, 0, 0, .9);
    background-color: transparent;
    backdrop-filter: blur(1px);
  }

  .dialog-footer .el-button {
    font-weight: bolder;
    color: black;
    border-radius: 20px;
    background-color: white;
    border-color: white;
  }

  /deep/ .el-radio__label {
    color: #fff;
  }

  .el-form {
    text-align: center;
  }
</style>
